import { BrowserRouter, Route, Redirect } from "react-router-dom";
import "./App.css";
import AboutMe from "./components/AboutMe/AboutMe";
import ContactMe from "./components/ContactMe/ContactMe";
import Footer from "./components/Footer/Footer";
import Home from "./components/Hero/Intro";
import Projects from "./components/MyWork/projects";
import Navbar from "./components/Navbar/Navbar";
import Services from "./components/Services/Services";
import Progress from "./components/SkillBars/progress";
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Email from "./components/EmailMe/Email";

function App() {
  // Preloader
  // $(window).on("load", function () {
  //   if ($("#preloader").length) {
  //     $("#preloader")
  //       .delay(100)
  //       .fadeOut("slow", function () {
  //         $(this).remove();
  //       });
  //   }
  // });

  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    });
  }, []);

  return (
    <>
      <BrowserRouter>
        <Route exact path="/" >
          <div className="homepage">
            <Navbar />
            <Home />
          </div>
          <AboutMe />
          <Services />
          <Progress />
          <Projects />
          <ContactMe />
          <Email />
        </Route>
        <Route path="/AboutMe" >
          <div className="homepage">
            <Navbar />
            <AboutMe />
          </div>
        </Route>
        <Route path="/Services" >
          <div className="homepage">
            <Navbar />
            <Services />
          </div>
        </Route>
        <Route path="/Progress" >
          <div className="homepage">
            <Navbar />
            <Progress />
          </div>
        </Route>
        <Route path="/project" >
          <div className="homepage">
            <Navbar />
            <Projects />
          </div>
        </Route>
        <Route path="/contact" >
          <div className="homepage">
            <Navbar />
            <ContactMe />
            <Email />
          </div>
        </Route>
        <Route path="*" >
          <Redirect to={'/'} />
        </Route>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
