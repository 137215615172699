import { child, get, getDatabase, ref } from "firebase/database";
import { useState, useEffect } from "react";
let url = "/portfolio/1/"

export const useFetch = (path) => {
    const [data, setData] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const [error, setError] = useState('');
    useEffect(() => {
        const daat = async () => {
            try {
                let snapshot = await get(child(global.dbRef, url + path))
                if (snapshot.exists()) {
                    console.log('snapshot.val()', url + path, snapshot.val());
                    console.log("data", snapshot.val());
                    setData(snapshot.val())
                    setIsFetching(false)
                } else {

                    console.log("No data available at ", url + path);
                    setData([])
                    setIsFetching(false)
                }

            } catch (error) {
                setData([])
                setIsFetching(false)
                setError(error.stack)
                console.error(error);
            }
        }
        daat();
        // get(child(global.dbRef, url)).then((snapshot) => {
        //     if (snapshot.exists()) {
        //         console.log("data", snapshot.val());
        //         setData(snapshot.val())
        //         setIsFetching(false)
        //     } else {
        //         console.log("No data available");
        //         setData([])
        //         setIsFetching(false)
        //     }
        // }).catch((error) => {
        //     setData([])
        //     setIsFetching(false)
        //     setError(error.stack)
        //     console.error(error);
        // });

    }, [path]);

    return { data, isFetching, error };
};
